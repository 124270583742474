<template>
  <div>
    <Banner
      v-if="flagShowGpOutageBanner"
      id="outage-banner"
      class="c-banner--alert"
      role="alert"
    >
      <span class="font-bold block">THE USPS® CHANGE OF ADDRESS WEBSITE IS TEMPORARILY UNAVAILABLE.</span>
      We are currently unable to service your online Change-of-Address. We apologize for the inconvenience, please try again later.
    </Banner>
    <Banner
      v-if="!!upcomingMaintenance"
      id="maintenance-banner"
      class="c-banner--alert"
      role="alert"
    >
      {{ upcomingMaintenance }}
    </Banner>
    <main
      class="disputes-mobile u-container"
    >
      <AppLoader
        v-if="loading"
        modal
      />
      <form
        ref="form"
        novalidate
        @submit.prevent="validate"
      >
        <header class="disputes-mobile__header">
          <h1
            id="disputes-heading"
            class="font-bold text-2xl"
          >
            Dispute a Change-of-Address Order
          </h1>
          <p class="mt-3">
            If you are not moving but received an Official USPS Change-of-Address (COA) Validation Letter in your name, file a dispute to flag the order for investigation.
            If the letter is not in your name, your mail will not be affected. No further action is necessary.
          </p>
          <p class="mt-2">
            If you are disputing a Change-of-Address for a family member or associate, please visit the local Post Office that handles delivery for your address.
          </p>
        </header>
        <Banner
          v-if="!flagCoaDisputesEnabled"
          id="banner-disputes-disabled"
          class="mt-3"
        >
          <p class="font-bold text-lg">
            Disputes service temporarily unavailable.
          </p>
          <p class="mt-2">
            We are currently unable to process online Change-of-Address order disputes.
          </p>
          <p class="mt-2">
            We apologize for the inconvenience, please try again later.
          </p>
        </Banner>
        <div class="c-hairline mt-6" />
        <section class="mt-6">
          <h2 class="font-bold text-xl">
            Enter the 13 digit Key and ZIP Code™ to get started
          </h2>
          <Tooltip class="mt-3">
            <template slot="tip">
              <p class="font-bold">
                What is a Change-of-Address Order Dispute?
              </p>
              <p>
                If you are not moving but received an Official USPS Change-of-Address Validation Letter, file a dispute to flag the order for investigation. USPS Support will be notified to investigate and determine the proper course of action.
              </p>
              <p class="font-bold mt-3">
                How to find your Change-of-Address Key?
              </p>
              <p>
                This key is printed on the front of the Official USPS Change-of-Address Validation Letter.
              </p>
              <p class="font-bold mt-3">
                What ZIP Code™ do I enter?
              </p>
              <p>
                Enter the ZIP Code™ that is on the Official USPS Change-of-Address Validation Letter.
              </p>
            </template>
            <template slot="accessible">
              How to find your Confirmation Code description
            </template>
          </Tooltip>
          <p
            v-if="errors.error.hasError || error"
            id="api-error"
            ref="apiError"
            class="text-sm text-red-200 font-bold mt-2"
          >
            {{ errors.error.message || error }}
          </p>
          <InputCoaKey
            id="disputes-key"
            ref="coaKey"
            v-model="coaKey"
            label="Key"
            placeholder="000-000-000-0000"
            name="disputes-key"
            class="mt-3"
            :has-error="errors.coaKey.hasError"
          >
            <template #helper>
              {{ errors.coaKey.message }}
            </template>
          </InputCoaKey>
          <InputZipCode
            id="disputes-zip-code"
            ref="zipcode"
            v-model="zipcode"
            label="ZIP Code™"
            placeholder="00000"
            name="disputes-zip-code"
            class="mt-3"
            :has-error="errors.zipcode.hasError"
          >
            <template #helper>
              {{ errors.zipcode.message }}
            </template>
          </InputZipCode>
          <p class="mt-5">
            Haven't changed you address yet?
          </p>
          <a
            id="back-to-mgo"
            :href="`${$options.mgoUrl}/`"
            class="c-link"
          >
            Submit a Change-of-Address order online.
          </a>
          <p class="mt-3">
            Need to make changes to a valid Change-of-Address?
          </p>
          <router-link
            id="back-to-coac"
            :to="{ name: 'home' }"
            class="c-link"
          >
            Edit, view, or cancel your Change-of-Address order online.
          </router-link>
          <div class="mt-5 mb-10">
            <a
              href="https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link mr-5"
            >
              Privacy Policy
            </a>
            <a
              :href="`${$options.mgoUrl}/mgo/terms-of-use`"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link"
            >
              Terms of Use
            </a>
          </div>
        </section>
        <div class="c-navbar">
          <button
            id="get-started"
            ref="getStartedMgoc"
            :disabled="!flagCoaDisputesEnabled"
            :class="{'c-btn--disabled': !flagCoaDisputesEnabled }"
            class="c-btn"
          >
            Get Started
          </button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import {
  AppLoader,
  Banner,
  InputCoaKey,
  InputZipCode,
  Tooltip,
} from '@mgo/ui/lib/components';
import destinationManagerMixin from '../../mixins/destinationManager';
import disputeMixin from './mixin';

export default {
  name: 'Disputes',
  components: {
    AppLoader,
    Banner,
    InputCoaKey,
    InputZipCode,
    Tooltip,
  },
  mixins: [
    disputeMixin,
    destinationManagerMixin,
  ],
};
</script>
