/**
 * Decision tree hash map
 *
 * The 'start' is the entry point for each decision tree node
 *
 * Numbered hashes represent recorded answers for previous questions
 * in the decision tree.
 * Example: if the answers to questions 1,2,3 are NO,YES,NO, the hash would be 010.
 *
 * Nodes that return an object with either a 'message' or a 'promptContact'
 * property designate an end node in the decision tree.
 *
 * Nodes with 'message' render a message to display to the user.
 *
 * Nodes with 'promptContact' render a form for the user to submit contact data
 * along with the recorded answers.
 *
 *
 */

const individual = {
  start: { label: 'Question 1: Is this letter addressed to your name?', reset: true },
  0: { label: 'Question 2: Do you know the person this letter is addressed to?' },
  '00': { label: 'Question 3: Did you recently move to this address?' },
  '000': {
    promptContact: false,
    addressee: false,
    nameRecognition: false,
    newResident: false,
    message: 'No further action is necessary.  Your mail will not be affected.',
  },
  '001': {
    addressee: false,
    nameRecognition: false,
    newResident: true,
    message: 'The USPS mails an Official Change-of-Address Letter to the old address. The previous resident may have filed a late COA. This will not affect the delivery of your mail.',
  },
  '01': {
    addressee: true,
    filedCoa: true,
    jointAddressee: true,
    label: 'Question 3: Has the person addressed on the letter ever lived at the address?',
    customOptions: [
      'NO / UNSURE',
      'YES',
    ],
  },
  '010': {
    addressee: false,
    nameRecognition: true,
    previousResident: false,
    message: 'If you are disputing a Change-of-Address for a family member or associate, please visit the local Post Office that handles delivery for your address.',
  },
  '011': {
    addressee: true,
    filedCoa: true,
    jointAddressee: true,
    nameRecognition: true,
    label: 'Question 4: Do you receive mail addressed to both your names on the same mail piece(s)?',
  },
  '0110': {
    addressee: false,
    nameRecognition: true,
    previousResident: true,
    jointAddressee: false,
    message: 'The USPS mails an Official Change-of-Address Letter to the old address. This will not affect the delivery of your mail.',
  },
  '0111': {
    addressee: false,
    nameRecognition: true,
    previousResident: true,
    jointAddressee: true,
    message: 'This will not affect mail that is addressed to you only. However, mail addressed to both names could be affected. Please contact your local Post Office regarding this matter.',
  },
  1: { label: 'Question 2: Have you filed a Change-of-Address in the past 10 days?' },
  11: {
    addressee: true,
    filedCoa: true,
    message: 'The USPS mails an Official Change-of-Address Letter to the old address. As a security measure, this letter does not contain your new address.',
  },
  10: {
    promptContact: true,
    addressee: true,
    filedCoa: false,
  },
  110: {
    addressee: true,
    filedCoa: true,
    jointAddressee: false,
    message: 'The USPS mails an Official Change-of-Address Letter to the old address. As a security measure, this letter does not contain your new address.',
  },
};

const family = {
  start: { label: 'Question 1: Is this letter addressed to your last name? (Ex: The Smith Family)', reset: true },
  0: { label: 'Question 2: Are you familiar with the last name on the letter?' },
  '00': {
    addressee: true,
    filedCoa: true,
    jointAddressee: true,
    label: 'Question 3: Did you recently move to this address?',
  },
  '000': {
    addressee: false,
    newResident: false,
    nameRecognition: false,
    message: 'If the letter is in your name, your mail will not be affected. No further action is necessary.',
  },
  '001': {
    addressee: false,
    nameRecognition: false,
    newResident: true,
    message: 'The USPS mails an Official Change-of-Address Letter to the old address. The previous resident may have filed a late COA. This will not affect the delivery of your mail.',
  },
  '01': {
    addressee: true,
    filedCoa: true,
    jointAddressee: true,
    label: 'Question 3: Has the person addressed on the letter ever lived at the address?',
    customOptions: [
      'NO / UNSURE',
      'YES',
    ],
  },
  '010': {
    addressee: false,
    nameRecognition: true,
    previousResident: false,
    message: 'If the letter is not in your last name and the person has never recieved mail at your address, your mail will not be  affected.<br/>No further action is necessary.',
  },
  '011': {
    addressee: true,
    filedCoa: true,
    jointAddressee: true,
    nameRecognition: true,
    label: 'Question 4: Do you receive mail addressed to both your names on the same mail piece(s)?',
  },
  '0110': {
    addressee: false,
    nameRecognition: true,
    previousResident: true,
    jointAddressee: false,
    message: 'The USPS mails an Official Change-of-Address Letter to the old address. This will not affect the delivery of your mail.',
  },
  '0111': {
    addressee: false,
    nameRecognition: true,
    previousResident: true,
    jointAddressee: true,
    message: 'This will not affect mail that is addressed to you only. However, mail addressed to both names could be affected. Please contact your local Post Office regarding this matter.',
  },
  1: { label: 'Question 2: Did you, or a family member, file a Change-of-Address in the past 10 days?' },
  10: {
    promptContact: true,
    addressee: true,
    filedCoa: false,
  },
  11: {
    addressee: true,
    filedCoa: true,
    jointAddressee: true,
    label: 'Question 3: Is anyone with the same last name remaining at this address?',
  },
  110: {
    addressee: true,
    filedCoa: true,
    remainAtAddress: false,
    message: 'The USPS mails an Official Change-of-Address Letter to the old address. As a security measure, this letter does not contain your new address.',
  },
  111: {
    addressee: true,
    filedCoa: true,
    remainAtAddress: true,
    message: ' ',
  },
};

const business = {
  start: { label: 'Question 1: Are you familiar with the business named on the letter?', reset: true },
  0: { label: 'Question 2: Did you recently move to this address?' },
  '00': {
    nameRecognition: false,
    newResident: false,
    message: 'If the letter is not in your business name, your mail will not be affected. No further action is necessary.',
  },
  '01': {
    nameRecognition: false,
    newResident: true,
    message: 'The USPS mails an Official Change-of-Address Letter to the old address. The previous business/resident may have filed a late COA. This will not affect the delivery of your mail.',
  },
  1: { label: 'Question 2: Did you file a COA for the business in the past 10 days?' },
  10: {
    promptContact: true,
    addressee: true,
    filedCoa: false,
  },
  11: {
    nameRecognition: true,
    filedCoa: true,
    message: 'The USPS mails an Official Change-of-Address Letter to the old address. As a security measure, this letter does not contain your new address.',
  },
};

export const questionMaps = {
  individual,
  family,
  business,
};
