import { object } from 'yup';
import DOMPurify from 'dompurify';
import {
  mapErrors,
  objectIsValid,
} from '@mgo/core/src/validations/utils';
import { questionMaps } from './questionMaps';
import validators from './validations';

export default {
  data() {
    return {
      moverType: '',
      moverTypeSelect: '',
      loading: true,
      lazy: true,
      error: false,
      success: false,

      // question input values
      answerOne: '',
      answerTwo: '',
      answerThree: '',
      answerFour: '',

      // form data
      firstName: '',
      lastName: '',
      zipCode: '',
      city: '',
      state: '',
      streetAddress: '',
      email: '',
      phone: '',
      preferredContactMethod: '',
    };
  },
  async mounted() {
    const { moverType } = await this.$dispute.search();
    this.moverType = moverType || '';
    this.moverTypeSelect = sessionStorage.getItem('moverType');

    this.loading = false;
  },
  computed: {
    answers() {
      return [
        this.answerOne,
        this.answerTwo,
        this.answerThree,
        this.answerFour,
      ].filter((answer) => answer);
    },
    defaultOptions() {
      return ['NO', 'YES'];
    },
    questionsTree() {
      if (!this.moverType) return {};

      return questionMaps[this.moverType.toLowerCase()] || {};
    },
    /* QUESTION ONE */
    questionOne() {
      return this.questionsTree?.start?.label || '';
    },
    questionOneOptions() {
      return this.questionsTree?.start?.customOptions || this.defaultOptions;
    },
    /* QUESTION TWO */
    questionTwo() {
      if (this.answers?.length < 1) return '';

      return this.questionsTree[this.answers.slice(0, 1).join('')]?.label || '';
    },
    questionTwoOptions() {
      return this.questionsTree[this.answers.slice(0, 1).join('')]?.customOptions || this.defaultOptions;
    },
    /* QUESTION THREE */
    questionThree() {
      if (this.answers?.length < 2) return '';

      return this.questionsTree[this.answers.slice(0, 2).join('')]?.label || '';
    },
    questionThreeOptions() {
      return this.questionsTree[this.answers.slice(0, 2).join('')]?.customOptions || this.defaultOptions;
    },
    /* QUESTION FOUR */
    questionFour() {
      if (!this.questionFourHash) return '';

      return this.questionsTree[this.questionFourHash]?.label || '';
    },
    questionFourHash() {
      if (this.answers?.length < 3) return '';

      return this.answers.slice(0, 3).join('');
    },
    questionFourOptions() {
      return this.questionsTree[this.answers.slice(0, 3).join('')]?.customOptions || this.defaultOptions;
    },
    contactUspsMessage() {
      if (!this.answers?.length) return '';

      return DOMPurify.sanitize(this.questionsTree[this.answers.join('')]?.message || '');
    },
    displayOn() {
      if (this.moverType === 'BUSINESS') {
        return !this.success || ['00', '01', '11', '001', '0111', '0110', '010', '000'].includes(this.answers.join(''));
      } if (this.moverType === 'FAMILY') {
        return !this.success || ['11', '001', '0111', '0110', '010', '000', '111', '110'].includes(this.answers.join(''));
      }
      // INDIVIDUAL
      return !this.success || ['11', '001', '0111', '0110', '010', '000'].includes(this.answers.join(''));
    },
    disableQuestion() {
      if (this.moverType === 'BUSINESS') {
        return this.success && ['010'].includes(this.answers.join(''));
      } if (this.moverType === 'FAMILY') {
        return this.success && [].includes(this.answers.join(''));
      }

      // INDIVIDUAL
      return this.success && [].includes(this.answers.join(''));
    },
    spcialMessage() {
      return (this.moverType === 'BUSINESS' && this.success && ['10'].includes(this.answers.join('')));
    },
    hideMessage() {
      if (this.moverType === 'BUSINESS') {
        return (this.success && ['11', '01'].includes(this.answers.join('')));
      } if (this.moverType === 'FAMILY') {
        return (this.success && ['110', '0111', '0110', '001'].includes(this.answers.join('')));
      }

      return !(this.success && ['000'].includes(this.answers.join('')));
    },
    showMessage() {
      if (this.moverType === 'BUSINESS') {
        return (this.success && ['11', '01'].includes(this.answers.join('')));
      } if (this.moverType === 'FAMILY') {
        return (this.success && ['111', '110', '0111', '0110', '001'].includes(this.answers.join('')));
      }

      return (this.success && ['11', '001', '010', '0110', '0111'].includes(this.answers.join('')));
    },
    showForm() {
      if (!this.answers?.length) return false;

      return this.questionsTree[this.answers.join('')]?.promptContact || false;
    },
    rules() {
      return object().shape(validators);
    },
    errors() {
      const fieldsToValidate = this.showForm
        ? [
          'firstName',
          'lastName',
          'zipCode',
          'city',
          'state',
          'streetAddress',
          'email',
          'phone',
          'preferredContactMethod',
        ]
        : [];
      return mapErrors(this, fieldsToValidate);
    },
    formIsValid() {
      return objectIsValid(this.errors);
    },
  },
  methods: {
    /**
     * Clear previous question and answer data that has become stale
     * Data becomes stale by changing the response to previously answered
     * questions which requires an updated hash
     * @param {Number} questionNumber - number of the question being answered
     */
    clearStaleAnswers(questionNumber) {
      switch (questionNumber) {
        case 1:
          this.answerTwo = '';
          this.answerThree = '';
          this.answerFour = '';
          break;
        case 2:
          this.answerThree = '';
          this.answerFour = '';
          break;
        case 3:
          this.answerFour = '';
          break;
        case 4:
          break;
        default:
          this.answerOne = '';
          this.answerTwo = '';
          this.answerThree = '';
          this.answerFour = '';
      }
    },
    async validate() {
      if (this.loading) return;

      this.error = false;
      this.success = false;
      this.lazy = false;
      await this.$nextTick();

      if (this.formIsValid) {
        this.submit();
      }
    },
    async submit() {
      this.loading = true;
      // /*
      const {
        addressee,
        filedCoa,
        jointAddressee,
        nameRecognition,
        newResident,
        previousResident,
        remainAtAddress,
      } = this.questionsTree[this.answers.join('')];

      const error = await this.$dispute.submit({
        // contact form fields
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        moverType: this.moverTypeSelect,
        phone: this.phone,
        preferredContactMethod: this.preferredContactMethod,
        streetAddress: this.streetAddress,
        city: this.city,
        state: this.state,
        zipCode: this.zipCode,

        // question answers
        addressee: addressee === 'undefined' ? null : addressee,
        filedCoa: filedCoa === 'undefined' ? null : filedCoa,
        jointAddressee: jointAddressee === 'undefined' ? null : jointAddressee,
        nameRecognition: nameRecognition === 'undefined' ? null : nameRecognition,
        newResident: newResident === 'undefined' ? null : newResident,
        previousResident: previousResident === 'undefined' ? null : previousResident,
        remainAtAddress: remainAtAddress === 'undefined' ? null : remainAtAddress,
      });
      // */
      // const error = false;

      if (error) {
        this.error = true;
        this.loading = false;
        return;
      }

      this.success = true;
      this.loading = false;
    },
  },
};
