import { object } from 'yup';
import {
  mapErrors,
  objectIsValid,
} from '@mgo/core/src/validations/utils';
import validators from './validations';

export default {
  data() {
    return {
      loading: false,
      lazy: true,
      coaKey: '',
      zipcode: '',
      error: '',
    };
  },
  computed: {
    flagCoaDisputesEnabled() {
      return this.$store.get('config@coaDisputesEnabled') || false;
    },
    flagShowGpOutageBanner() {
      return this.$store.get('config@showGpOutageBanner') || false;
    },
    upcomingMaintenance() {
      return this.$store.get('config@upcomingMaintenance') || '';
    },
    rules() {
      return object().shape(validators);
    },
    errors() {
      return mapErrors(this, ['coaKey', 'zipcode', 'error']);
    },
    formIsValid() {
      return objectIsValid(this.errors);
    },
  },
  methods: {
    async validate() {
      if (this.loading) return;

      this.error = '';
      this.lazy = false;
      await this.$nextTick();

      if (this.formIsValid) {
        this.submit();
      }
    },
    async submit() {
      this.loading = true;
      const resp = await this.$dispute.login(this.coaKey, this.zipcode);

      if (resp?.exception) {
        this.error = resp?.message || 'An error has occurred.';
      }

      if (!this.error) {
        this.next();
      }

      this.loading = false;
    },
    next() {
      this.$router.push({ name: 'disputes-questionnaire' });
    },
  },
};
