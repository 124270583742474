/* eslint-disable func-names */
import { string } from 'yup';

export const coaKey = string()
  .required('Please provide a valid Key.')
  .max(13, 'Please provide a valid Key.')
  .min(13, 'Please provide a valid Key.');

export const zipCode = string()
  .label('Zip Code')
  .min(5, 'The ZIP Code™ should be 5 digits long.')
  .max(5, 'The ZIP Code™ should be 5 digits long.')
  .required('This field cannot be empty.');

export const city = string()
  .label('City')
  .required('This field cannot be empty.');

export const state = string()
  .label('State')
  .required('Please select a state or territory.');

export const streetAddress = string()
  .label('Street Address')
  .required('This field cannot be empty.');

export const preferredContactMethod = string()
  .required('Please enter a preferred contact method.');
