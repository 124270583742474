import { string } from 'yup';
import { coaKey } from '@mgo/core/src/validations/dispute';

const zipcode = string()
  .required('The ZIP Code™ field cannot be left blank.')
  .test(
    'min-max',
    'The ZIP Code™ field should contain five numbers.',
    (value) => {
      if (value) {
        return value.length === 5;
      }

      return true;
    },
  );

const error = string()
  .test(
    'NCOAClientException',
    'Sorry, there was a problem processing your information. Your order cannot be accessed with the login information provided. Please check that you have entered it correctly.',
    (value) => value !== 'NCOAClientException',
  );

export default {
  coaKey,
  zipcode,
  error,
};
