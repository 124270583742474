<template>
  <div>
    <AppLoader
      v-if="loading"
      modal
    />
    <main class="u-container">
      <header class="lg:w-full">
        <h1 class="font-bold">
          Dispute a Change-of-Address Order
        </h1>
        <p class="mt-5">
          If you are not moving but received an Official USPS Change-of-Address (COA) Validation Letter in your name, file a dispute to flag the order for investigation. If the letter is not in your name, your mail will not be affected. No further action is necessary.
        </p>
        <p class="mt-3">
          If you are disputing a Change-of-Address for a family member or associate, please visit the local Post Office that handles delivery for your address.
        </p>
      </header>
      <div class="c-hairline mt-6" />
      <form
        ref="form"
        novalidate
        @submit.prevent="validate"
      >
        <section
          v-if="displayOn"
          class="flex my-8"
        >
          <div class="lg:w-1/2">
            <h2
              v-if="questionOne && !showForm"
              class="text-2xl font-bold"
            >
              Please fill out the questionnaire below to aid USPS Support's investigation and determine the proper course of action.
            </h2>
            <InputDropdown
              v-if="questionOne && !disableQuestion"
              id="question-one"
              ref="questionOne"
              v-model="answerOne"
              :disabled="success"
              :label="questionOne"
              name="question-one"
              class="mt-5"
              @input="clearStaleAnswers(1);"
            >
              <option value="">
                Select Option
              </option>
              <option
                v-for="(option, i) in questionOneOptions"
                :key="option"
                :value="i"
              >
                {{ option.toUpperCase() }}
              </option>
            </InputDropdown>
            <InputDropdown
              v-if="questionTwo && !disableQuestion"
              id="question-two"
              ref="questionTwo"
              v-model="answerTwo"
              :label="questionTwo"
              :disabled="success"
              name="question-two"
              class="mt-5"
              @input="clearStaleAnswers(2);"
            >
              <option value="">
                Select Option
              </option>
              <option
                v-for="(option, i) in questionTwoOptions"
                :key="option"
                :value="i"
              >
                {{ option.toUpperCase() }}
              </option>
            </InputDropdown>
            <InputDropdown
              v-if="questionThree && !disableQuestion"
              id="question-three"
              ref="questionThree"
              v-model="answerThree"
              :label="questionThree"
              :disabled="success"
              name="question-three"
              class="mt-5"
              @input="clearStaleAnswers(3);"
            >
              <option value="">
                Select Option
              </option>
              <option
                v-for="(option, i) in questionThreeOptions"
                :key="option"
                :value="i"
              >
                {{ option.toUpperCase() }}
              </option>
            </InputDropdown>
            <InputDropdown
              v-if="questionFour && !disableQuestion"
              id="question-four"
              ref="questionFour"
              v-model="answerFour"
              :label="questionFour"
              :disabled="success"
              name="question-four"
              class="mt-5"
              @input="clearStaleAnswers(4);"
            >
              <option value="">
                Select Option
              </option>
              <option
                v-for="(option, i) in questionFourOptions"
                :key="option"
                :value="i"
              >
                {{ option.toUpperCase() }}
              </option>
            </InputDropdown>
            <!-- End node: Contact USPS with questions -->
            <div
              v-if="!!contactUspsMessage"
              id="end-message"
              ref="endMessage"
            >
              <br>
              <button
                v-if="!showForm && !success"
                id="contact-submit"
                ref="submit"
                class="c-btn"
              >
                Submit
              </button>
              <!-- eslint-disable vue/no-v-html -->
              <p
                v-if="success"
                class="text-red-200 mt-5"
                v-html="contactUspsMessage"
              />
              <!--eslint-enable-->
              <p
                v-if="showMessage && contactUspsMessage !== ' '"
                class="text-red-200 mt-3"
              >
                No further action is necessary.
              </p>

              <p
                v-if="showMessage && !hideMessage"
                class="text-red-200 mt-5"
              >
                Email <a
                  class="c-link--inline"
                  href="href:mailto:COAHelp@usps.gov"
                >COAHelp@usps.gov</a>. Please include the name and
                address that is on the Change-of-Address letter.
              </p>

              <p
                v-if="showMessage && hideMessage"
                class="text-red-200 mt-5"
              >
                If you have additional questions, please contact <a
                  class="c-link--inline"
                  href="href:mailto:COAHelp@usps.gov"
                >COAHelp@usps.gov</a>.
              </p>
            </div>
            <!-- End node: Form for submitting dispute -->
            <div
              v-if="showForm"
              id="contact-form"
              ref="contactFormFields"
            >
              <p
                id="contact-form-message"
                class="text-red-200 mt-5"
              >
                The COA support team will need your information for a representative to follow up with you as soon as possible.
              </p>
              <div class="flex">
                <InputText
                  id="first-name"
                  ref="firstName"
                  v-model.trim="firstName"
                  label="First Name"
                  placeholder="First Name"
                  name="first-name"
                  class="w-1/2 mt-5"
                  :has-error="errors.firstName.hasError"
                >
                  <template #helper>
                    {{ errors.firstName.message }}
                  </template>
                </InputText>
                <InputText
                  id="last-name"
                  ref="lastName"
                  v-model.trim="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  name="last-name"
                  class="w-1/2 mt-5 ml-6"
                  :has-error="errors.lastName.hasError"
                >
                  <template #helper>
                    {{ errors.lastName.message }}
                  </template>
                </InputText>
              </div>
              <InputText
                id="disputes-zip-code"
                ref="zipCode"
                v-model.trim="zipCode"
                label="ZIP Code™"
                placeholder="00000"
                name="zip-code"
                class="mt-5"
                :has-error="errors.zipCode.hasError"
              >
                <template #helper>
                  {{ errors.zipCode.message }}
                </template>
              </InputText>
              <div class="flex">
                <InputText
                  id="#disputes-city"
                  ref="city"
                  v-model.trim="city"
                  label="City"
                  placeholder="City"
                  name="city"
                  class="w-1/2 mt-5"
                  :has-error="errors.city.hasError"
                >
                  <template #helper>
                    {{ errors.city.message }}
                  </template>
                </InputText>
                <InputState
                  id="disputes-state"
                  ref="state"
                  v-model="state"
                  label="State"
                  name="state"
                  class="w-1/2 mt-5 ml-6"
                  :has-error="errors.state.hasError"
                >
                  <template #helper>
                    {{ errors.state.message }}
                  </template>
                </InputState>
              </div>
              <InputText
                id="disputes-street-address"
                ref="streetAddress"
                v-model.trim="streetAddress"
                label="Street Address"
                placeholder="12345 Main Street"
                name="street-address"
                class="mt-5"
                :has-error="errors.streetAddress.hasError"
              >
                <template #helper>
                  {{ errors.streetAddress.message }}
                </template>
              </InputText>
              <InputText
                id="email"
                ref="email"
                v-model.trim="email"
                label="Email Address"
                placeholder="Email Address"
                name="email"
                class="mt-5"
                :has-error="errors.email.hasError"
              >
                <template #helper>
                  {{ errors.email.message }}
                </template>
              </InputText>
              <InputPhone
                id="phone"
                ref="phone"
                v-model="phone"
                label="Phone Number"
                placeholder="Phone Number"
                name="phone-number"
                class="mt-5"
                :has-error="errors.phone.hasError"
              >
                <template #helper>
                  {{ errors.phone.message }}
                </template>
              </InputPhone>
              <InputDropdown
                id="preferred-contact"
                ref="preferredContact"
                v-model="preferredContactMethod"
                label="Preferred Contact Method"
                name="preferred-contact-method"
                class="mt-5"
                :has-error="errors.preferredContactMethod.hasError"
              >
                <option value="">
                  Select Option
                </option>
                <option value="EMAIL">
                  Email
                </option>
                <option value="PHONE">
                  Phone
                </option>
                <template #helper>
                  {{ errors.preferredContactMethod.message }}
                </template>
              </InputDropdown>
              <Banner
                v-if="error"
                id="error-message"
                ref="errorMessage"
                class="mt-8"
              >
                <p class="font-bold">
                  Disputes service temporarily unavailable.
                </p>
                <p class="mt-2">
                  We are currently unable to process online Change-of-Address order disputes.
                </p>
                <p class="mt-2">
                  We apologize for the inconvenience, please try again later.
                </p>
              </Banner>
            </div>
          </div>
        </section>
        <section
          v-if="success"
          id="success-message"
          ref="successMessage"
          class="mt-8"
        >
          <p
            v-if="!disableQuestion && showForm"
          >
            You have successfully submitted a dispute for this Change-of-Address order. A representative of the COA support team will contact you as soon as possible.
          </p>
        </section>
        <div
          v-if="spcialMessage"
          class="c-hairline mt-6"
        />
        <section
          v-if="success"
          id="success-message"
          ref="successMessage"
          class="mt-8"
        >
          <p
            v-if="spcialMessage"
          >
            The Change-of-Address must be in the business name. If you are disputing a Change-of-Address on behalf of someone else, please visit the local Post Office that handles delivery for this address.
          </p>
        </section>
        <div class="c-hairline mt-6" />
        <section class="flex mt-8">
          <div class="w-1/2">
            <button
              v-if="showForm && !success"
              id="contact-submit"
              ref="submit"
              class="c-btn"
            >
              Submit
            </button>
            <p :class="{ 'mt-5': showForm && !success }">
              Haven't changed your address yet?
            </p>
            <a
              :href="`${$options.mgoUrl}/mgo/disclaimer`"
              class="c-link"
            >
              Submit a Change-of-Address order online.
            </a>
            <p class="mt-5">
              Need to make changes to a valid Change-of-Address?
            </p>
            <router-link
              :to="{ name: 'home' }"
              class="c-link"
            >
              Edit, view, or cancel your Change-of-Address order online.
            </router-link>
          </div>
          <div class="w-1/2 text-right">
            <a
              href="https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link mr-5"
            >
              Privacy Policy
            </a>
            <a
              :href="`${$options.mgoUrl}/mgo/terms-of-use`"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link"
            >
              Terms of Use
            </a>
          </div>
        </section>
      </form>
    </main>
  </div>
</template>

<script>
import {
  AppLoader,
  Banner,
  InputDropdown,
  InputPhone,
  InputState,
  InputText,
} from '@mgo/ui/lib/components';
import destinationManagerMixin from '../../mixins/destinationManager';
import questionnaireMixin from './mixin';

export default {
  name: 'Questionnaire',
  components: {
    AppLoader,
    Banner,
    InputDropdown,
    InputPhone,
    InputState,
    InputText,
  },
  mixins: [
    questionnaireMixin,
    destinationManagerMixin,
  ],
};
</script>
