import {
  firstName,
  lastName,
  email,
  phoneNumber as phone,
} from '@mgo/core/src/validations/person';
import {
  zipCode,
  city,
  state,
  streetAddress,
  preferredContactMethod,
} from '@mgo/core/src/validations/dispute';

export default {
  firstName,
  lastName,
  zipCode,
  city,
  state,
  streetAddress,
  email,
  phone,
  preferredContactMethod,
};
