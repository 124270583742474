<template>
  <div>
    <Banner
      v-if="flagShowGpOutageBanner"
      id="outage-banner"
      class="c-banner--alert"
      role="alert"
    >
      <span class="font-bold block">THE USPS® CHANGE OF ADDRESS WEBSITE IS TEMPORARILY UNAVAILABLE.</span>
      We are currently unable to service your online Change-of-Address. We apologize for the inconvenience, please try again later.
    </Banner>
    <Banner
      v-if="!!upcomingMaintenance"
      id="maintenance-banner"
      class="c-banner--alert"
      role="alert"
    >
      {{ upcomingMaintenance }}
    </Banner>
    <main
      class="disputes-desktop u-container"
    >
      <AppLoader
        v-if="loading"
        modal
      />
      <form
        ref="form"
        novalidate
        @submit.prevent="validate"
      >
        <header class="flex disputes-desktop__header">
          <div class="lg:w-full">
            <h1
              id="disputes-heading"
              class="font-bold"
            >
              Dispute a Change-of-Address Order
            </h1>
            <p class="mt-5">
              If you are not moving but received an Official USPS Change-of-Address (COA) Validation Letter in your name, file a dispute to flag the order for investigation.
              If the letter is not in your name, your mail will not be affected. No further action is necessary.
            </p>
            <p class="mt-3">
              If you are disputing a Change-of-Address for a family member or associate, please visit the local Post Office that handles delivery for your address.
            </p>
          </div>
        </header>
        <Banner
          v-if="!flagCoaDisputesEnabled"
          id="banner-disputes-disabled"
          class="mt-6"
        >
          <p class="font-bold text-lg">
            Disputes service temporarily unavailable.
          </p>
          <p class="mt-2">
            We are currently unable to process online Change-of-Address order disputes.
          </p>
          <p class="mt-2">
            We apologize for the inconvenience, please try again later.
          </p>
        </Banner>
        <div class="c-hairline mt-6" />
        <section class="flex my-8">
          <div class="w-1/2 pr-xl">
            <h2 class="text-2xl font-bold">
              Enter the 13 digit Key and ZIP Code™ to get started
            </h2>
            <p
              v-if="errors.error.hasError || error"
              id="api-error"
              ref="apiError"
              class="text-sm text-red-200 font-bold mt-2"
            >
              {{ errors.error.message || error }}
            </p>
            <InputCoaKey
              id="disputes-key"
              ref="coaKey"
              v-model="coaKey"
              label="Key"
              placeholder="000-000-000-0000"
              name="disputes-key"
              class="mt-3"
              :has-error="errors.coaKey.hasError"
            >
              <template #helper>
                {{ errors.coaKey.message }}
              </template>
            </InputCoaKey>
            <InputZipCode
              id="disputes-zip-code"
              ref="zipcode"
              v-model="zipcode"
              label="ZIP Code™"
              placeholder="00000"
              name="disputes-zip-code"
              class="mt-3"
              :has-error="errors.zipcode.hasError"
            >
              <template #helper>
                {{ errors.zipcode.message }}
              </template>
            </InputZipCode>
          </div>
          <div class="w-1/2 max:pl-xl">
            <h3 class="font-bold">
              More Information
            </h3>
            <h4 class="mt-3 text-lg">
              <strong>What is a Change-of-Address Order Dispute?</strong>
            </h4>
            <p>If you are not moving but received an Official USPS Change-of-Address Validation Letter, file a dispute to flag the order for investigation. USPS Support will be notified to investigate and determine the proper course of action.</p>
            <h4 class="mt-3 text-lg">
              <strong>How to find your Change-of-Address Key?</strong>
            </h4>
            <p>This key is printed on the front of the Official USPS Change-of-Address Validation Letter.</p>
            <h4 class="mt-3 text-lg">
              <strong>What ZIP Code™ do I enter?</strong>
            </h4>
            <p>Enter the ZIP Code™ that is on the Official USPS Change-of-Address Validation Letter.</p>
          </div>
        </section>
        <div class="c-hairline mt-6" />
        <section class="flex mt-8">
          <div class="w-1/2">
            <button
              id="get-started"
              ref="submit"
              :disabled="!flagCoaDisputesEnabled"
              :class="{'c-btn--disabled': !flagCoaDisputesEnabled }"
              class="c-btn"
            >
              Get Started
            </button>
            <p class="mt-5">
              Haven't changed your address yet?
            </p>
            <a
              id="back-to-mgo"
              :href="`${$options.mgoUrl}/`"
              class="c-link"
            >
              Submit a Change-of-Address order online.
            </a>
            <p class="mt-5">
              Need to make changes to a valid Change-of-Address?
            </p>
            <router-link
              id="back-to-coac"
              :to="{ name: 'home' }"
              class="c-link"
            >
              Edit, view, or cancel your Change-of-Address order online.
            </router-link>
          </div>
          <div class="w-1/2 text-right">
            <a
              href="https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link mr-5"
            >
              Privacy Policy
            </a>
            <a
              :href="`${$options.mgoUrl}/mgo/terms-of-use`"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link"
            >
              Terms of Use
            </a>
          </div>
        </section>
      </form>
    </main>
  </div>
</template>

<script>
import {
  AppLoader,
  Banner,
  InputCoaKey,
  InputZipCode,
} from '@mgo/ui/lib/components';
import destinationManagerMixin from '../../mixins/destinationManager';
import disputeMixin from './mixin';

export default {
  name: 'Disputes',
  components: {
    AppLoader,
    Banner,
    InputCoaKey,
    InputZipCode,
  },
  mixins: [
    disputeMixin,
    destinationManagerMixin,
  ],
};
</script>
